<template lang="pug">
  #calendar-header
    #tooltip-container
    .settings-wrapper
      .settings
        .visibility-action
          AppButtonWithIcon.m-l-5(
            :title="filtersTitle"
            :icon="filtersIcon"
            @click="toggleFilterVisibility"
          )
        .sale-stop-actions.justify-content-between.align-items-baseline.w-100.m-r-10(
          :class="{'d-flex': isFiltersVisible }"
        )
          .sale-stop-all(v-if="isFiltersVisible") {{ $t("activerecord.attributes.inventory.all_classes_sale_stop") }}
          ExpandCollapseIcon.m-r-10(
            v-if="isInventoriesPresent"
            :toggler-names="togglerNames"
            :car-classes="carClasses"
          )
    ScrollSync.calendar-header-months(ref="calendarHeaderMonths" horizontal)
      .months
        .month-wrapper.p-t-15(v-for="month, monthTitle in groupedDateRange")
          .month-title-container(
            :key="monthTitle"
            :title="monthTitle"
          )
            p.month-title(
              :class="`days-${month.length}`"
            ) {{ monthTitleByLength(monthTitle, month) }}

          .days
            .day(
              v-for="day in month"
              :key="`${month}-${day.getDate()}`"
              :class="buildDayClassObject(day)"
            )
              p.date(
                :title="japaneseHolidayName(day)"
                v-b-tooltip.hover="{ container: '#tooltip-container' }"
              ) {{ day.getDate() }}
                .circle(v-if="japaneseHolidayName(day)")
              p.week-day.p-t-3.p-b-6(v-if="isFiltersVisible") {{ localizedDayName(day) }}
              AppCheckbox(
                v-if="isFiltersVisible"
                :class="getSaleStopClasses(getFormattedDate(day))"
                :value="getSaleStopDayValue(getFormattedDate(day))"
                :indeterminate="getSaleStopDayIndeterminate(getFormattedDate(day))"
                :indeterminate-icon="'hypen'"
                :disabled="!hasEditPermission() || !editable || day < currentDate"
                @change="$emit('change-sale-stop-days', day, $event)"
                :highlight-on-hover="false"
              )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withModal from "@/mixins/withModal"
  import withConfirmation from "@/mixins/withConfirmation"

  // misc
  import { format as dateFormat, isWeekend, isSunday } from "date-fns"
  import { lowerCase, memoize } from "lodash-es"
  import { isHoliday } from "japanese-holidays"
  import { monthTitleByLength } from "@/helpers/calendar-header"
  import { getFormattedDate } from "@/helpers/dates"

  import { TOGGLER_NAMES } from "./constants"

  export default {
    props: {
      // {"2020-03": [Date]}
      groupedDateRange: {
        type: Object,
        default: () => new Object()
      },
      saleStopDays: {
        type: Object,
        default: () => ({})
      },
      editable: {
        type: Boolean,
        default: false
      },
      currentDate: {
        type: Date,
        default: () => new Date()
      },
      isInventoriesPresent: {
        type: Boolean,
        default: false
      },
      isFiltersVisible: {
        type: Boolean,
        default: true
      },
      carClasses: {
        type: Array,
        default: () => new Array()
      }
    },

    components: {
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppButtonWithIcon: () => import("@/components/elements/AppButton/WithIcon/Other"),
      ExpandCollapseIcon: () => import("@/components/InventoryManagement/ExpandCollapse")
    },

    mixins: [withPermissions, withModal, withConfirmation],

    data() {
      return {
        togglerNames: TOGGLER_NAMES
      }
    },

    computed: {
      filtersTitle() {
        return this.isFiltersVisible ? "inventory_management.close_filters" : "inventory_management.open_filters"
      },

      filtersIcon() {
        return this.isFiltersVisible ? "chevron-up" : "chevron-down"
      }
    },

    methods: {
      isWeekend,
      isSunday,
      monthTitleByLength,
      getFormattedDate,

      buildDayClassObject(day) {
        return {
          weekend: this.isWeekend(day) || this.japaneseHolidayName(day),
          sunday: this.isSunday(day) || this.japaneseHolidayName(day)
        }
      },

      localizedDayName(day) {
        const dayName = lowerCase(dateFormat(day, "EEEE"))

        return this.$t(`datepicker.weekdays_min.${dayName}`)
      },

      japaneseHolidayName: memoize(day => isHoliday(day)),

      resetHeaderScroll() {
        this.$refs.calendarHeaderMonths.$el.scrollLeft = 0
      },

      getSaleStopClasses(day) {
        const saleStopDayChanged = this.getSaleStopDayChanged(day)
        return {
          "purple-filled": this.getSaleStopDayValue(day) && !saleStopDayChanged,
          "grey-filled": this.getSaleStopDayIndeterminate(day) && !saleStopDayChanged,
          highlight: saleStopDayChanged
        }
      },

      getSaleStopDayChanged(day) {
        return this.saleStopDays[day]?.changed
      },

      getSaleStopDayValue(day) {
        return this.saleStopDays[day]?.value || false
      },

      getSaleStopDayIndeterminate(day) {
        return this.saleStopDays[day]?.indeterminate || false
      },

      toggleFilterVisibility() {
        this.$emit("toggle-filter-visibility")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/price-calendar.sass"

  #calendar-header
    border-bottom: 1px solid $default-purple-light-line
    display: flex

    #tooltip-container
      +tooltip($default-red)

    .settings-wrapper
      border-right: 1px solid $default-purple-light-line
      background-color: $default-white
      display: flex
      // Both are required in order to make it work in chrome and ie at the same time
      width: 240px
      min-width: 240px

      .settings
        align-items: flex-start
        display: flex
        flex-direction: column
        justify-content: space-between
        width: 100%

        .visibility-action
          button
            border: none

            &:hover
              box-shadow: none

        .sale-stop-actions
          @media screen and (max-width: 1400px)
            margin-bottom: 12px

        .sale-stop-all
          color: $link-color
          margin: 0
          font-size: 0.8rem
          font-weight: 800
          text-transform: uppercase
          margin-bottom: 10px

    .calendar-header-months
      overflow-x: auto

      .months
        display: flex

        .month-wrapper
          background-color: $th-background-color
          border-right: 1px solid $default-purple-light-line

          &:last-child
            border-right: 1px solid $default-purple-light-line

          .week-day
            background-color: $default-white

          .month-title-container
            white-space: nowrap
            height: 35px

            .month-title
              padding: 0 10px
              color: $default-purple

              font-weight: 900
              width: fit-content
              position: sticky
              left: 0
              &.days-1
                max-width: 36px

          .days
            display: flex

            .day
              width: 37px

              position: relative

              &:hover .holiday-tooltip
                display: block

              .date
                border-right: 1px solid transparentize($default-purple, 0.8)
                cursor: default

              .circle
                position: absolute
                border: 1px solid $default-red
                width: 24px
                height: 24px
                top: 2px
                left: 0
                right: 1px
                margin: 0 auto
                border-radius: 50%
                pointer-events: none

              &:last-child
                margin-left: -1px
                .date
                  border-right: none!important

            p
              color: $default-purple
              font-size: 0.8rem
              line-height: 30px
              margin: 0
              text-align: center
              width: 37px

            .week-day
              border: 0
              background-color: $default-white

            .weekend
              font-weight: 900

            .sunday
              p
                color: $default-red

          ::v-deep
            .app-checkbox
              align-items: center
              background-color: $default-white
              display: flex
              height: 32px
              justify-content: center
              padding: 0
              width: 37px

              &.highlight
                .app-checkbox-icon
                  background: #f3f4f6 !important
                  border-color: $default-gray-medium
                  svg, .svg-fillable
                    fill: $default-gray !important

              &.purple-filled
                .app-checkbox-icon
                  background-color: $default-purple
                  .svg-fillable
                    fill: $default-white

              &.grey-filled
                .app-checkbox-icon
                  background-color: $default-gray
                  border-color: $default-gray-medium
                  .svg-fillable
                    fill: $default-white

              .app-checkbox-icon
                margin-right: 0
              .app-checkbox-icon
                margin-right: 0
</style>
