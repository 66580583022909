<template lang="pug">
  tr.account(v-if="!isEmpty(account)")
    td.sync
      AppToggle(
        :value="account.active"
        :disabled="!allowUpdateAccount || disabled"
        @input="throttledToggle"
      )
    td.editable
      input.editable-field.username-input.form-control(
        type="text"
        name="login"
        v-model="account.username"
        :class="{ changed: isChanged('username') }"
        :disabled="!allowUpdateAccount"
        @input="handleUsernameInput"
      )
    td.editable
      AppInputPassword(
        :class="{ 'password-edited': isChanged('password') }"
        v-model="account.password"
        :disabled="!allowUpdateAccount"
        @input="handlePasswordInput"
      )
    td
      .actions
        AppProgressbar(
          v-if="isImportInProgress"
          :label="$t('components.app_progressbar.getting_data')"
          :value="importProgress.value"
        )
        template(v-else)
          .hints
            .hint {{ $t("ota_accounts.last_update_at", { date: lastImportFinishedAt }) }}
            .hint.login-failed-warning(v-if="isFailed") {{ warningText }}
          .get-data-button
            //- TODO: investigate about component loading prop
            AppLoadingButton(
              :loading="account.loading"
              title="actions.get_data_from_ota"
              :disabled="isGetDataDisabled"
              @click="throttledSave"
            )
        .action-icon(
          v-b-tooltip.noninteractive.hover="{ title: blockedTooltipTitle, placement: 'center', disabled: !account.active }"
        )
          AppSpinner(v-if="loading")
          AppIconButton.trash-icon(
            v-else-if="hasEditPermission()"
            icon="trash-alt"
            @click="handleDelete"
            :disabled="isImportInProgress || account.active"
          )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withConfirmation from "@/mixins/withConfirmation"

  // misc
  import { at, isEmpty, isEqual, throttle } from "lodash-es"

  export default {
    components: {
      AppLoadingButton: () => import("@/components/elements/AppButton/WithIcon/Loading"),
      AppProgressbar: () => import("@/components/elements/AppProgressbar"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppInputPassword: () => import("@/components/elements/AppInputPassword"),
      AppSpinner: () => import("@/components/elements/AppSpinner"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      item: {
        type: Object,
        required: true
      },
      ota: {
        type: Object,
        required: true
      },
      importProgress: {
        type: Object,
        default: () => ({ value: 0 })
      },
      // need for disabling sync and get_data_from_ota buttons for Rennavi OTA
      // TODO: remove after be able to work with Rennavi
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        initialAccount: {},
        account: {},
        loading: false
      }
    },

    mixins: [withConfirmation, withPermissions],

    mounted() {
      this.setAccount()
      this.setInitialAccount()
    },

    watch: {
      item: {
        handler() {
          this.setAccount()
        },
        deep: true
      },

      isImportInProgress(newValue) {
        if (!newValue) {
          this.$root.$emit("bv::hide::tooltip")
        }
      }
    },

    computed: {
      isGetDataDisabled() {
        return !this.isSaveButtonActive || !this.allowUpdateAccount || this.disabled || this.account.loading
      },

      currentActive() {
        return this.account.current_active
      },

      isFailed({ isLoginFailed, isImportFailed }) {
        return isLoginFailed || isImportFailed
      },

      warningText({ isLoginFailed }) {
        return isLoginFailed ? this.$t("ota_accounts.login_failed") : this.$t("ota_accounts.import_failed")
      },

      isLoginFailed() {
        return !!this.importProgress?.loginFailed
      },

      isImportFailed() {
        return !!this.importProgress?.importFailed
      },

      isAccountExists() {
        return this.account.id !== undefined
      },

      isAccountSaved({ isAccountExists, account, item }) {
        const attrs = ["active", "username"]

        return isAccountExists && isEqual(at(account, attrs), at(item, attrs))
      },

      isSaveButtonActive({ loading }) {
        const { active, username, password } = this.account
        const isPasswordExists = this.isAccountExists ? true : !isEmpty(password)

        return active === true && !isEmpty(username) && isPasswordExists && !loading
      },

      allowUpdateAccount() {
        return this.hasEditPermission() && !this.isImportInProgress
      },

      lastImportFinishedAt() {
        return this.importProgress.lastImportFinishedAt || "—"
      },

      isImportInProgress() {
        return this.account.import_in_progress
      },

      blockedTooltipTitle() {
        return this.isImportInProgress
          ? this.$t("ota_accounts.blocked_deleting")
          : this.$t("ota_accounts.blocked_deleting_when_sinc_on")
      }
    },

    methods: {
      isEmpty,

      handleUsernameInput({ target: { value } }) {
        this.$emit("set-account", { ...this.item, username: value })
      },

      handlePasswordInput(value) {
        this.$emit("set-account", { ...this.item, password: value })
      },

      setInitialAccount() {
        this.initialAccount = { ...this.account }
      },

      setAccount() {
        this.account = {
          ...this.item,
          password: this.item.password || "",
          ota: this.ota
        }
      },

      isChanged(fieldName) {
        return this.initialAccount[fieldName] !== this.account[fieldName]
      },

      throttledToggle: throttle(function(value) {
        // we need to ask user do he really want to disable OTA account
        // and if he wants - we immediately send update request with active=false
        // in another cases (account still not saved or it toggled to ON) -
        // we just change `active` field
        if (this.isAccountSaved && this.currentActive && value === false) {
          this.$confirm({
            title: this.$t("ota_accounts.inactivate_warning"),
            resolve: {
              handler: () => {
                this.account = { ...this.account, active: value }
                this.$emit("deactivate")
              }
            }
          })
        } else {
          this.$emit("set-account", { ...this.item, active: value })
        }
      }),

      throttledSave: throttle(function() {
        this.$emit("save", this.account)
      }, 1000),

      handleDelete() {
        if (this.isAccountExists) {
          this.$confirm({
            title: this.$t("ota_accounts.delete_confirmation"),
            resolve: {
              handler: () => {
                this.$emit("delete")
                this.loading = true
              }
            }
          })
        } else {
          this.$emit("delete")
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/helpers.sass"
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/mixins/common.sass"

  .account
    td
      +custom-table-td

      &.sync
        width: 80px

      &.editable
        min-width: 80px !important

        .editable-field
          width: 100%
          height: 30px
          line-height: 2.5

          +ie-only
            line-height: 0

        ::v-deep
          .password-input
            border: 1px solid rgba(89, 101, 231, 0.50)
            height: 30px

            &-icon
              top: 10px

          .username-input
            border: 1px solid rgba(89, 101, 231, 0.50)
            height: 30px

        .password-edited
          ::v-deep
            input
              color: $default-purple !important
              background: $default-purple-light

    .hints
      display: flex
      flex-direction: column
      align-items: flex-end

    .hint
      font-size: 0.8rem
      display: flex
      align-items: center
      color: $default-black

      &.login-failed-warning
        color: $default-red-dark

    input.editable-field
      &.changed
        color: $default-purple !important
        background: $default-purple-light

    .actions
      align-items: center
      justify-content: flex-end
      display: flex

      & > *
        margin-left: 20px
        text-align: right

      .action-icon
        .trash-icon
          +icon-button($default-purple)

  /* IE11 */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    .account
      td
        white-space: nowrap

        &.editable
          min-width: 200px !important
</style>
